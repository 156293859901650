/*------------------------------------
	blog sidebar
------------------------------------*/
.blog-sidebar {
	@include widther(1200px) {
		padding-left: 45px;
	}

	@include media-query(991px) {
		margin-top: 80px;
		max-width: 400px;
	}

	@include media-query(767px) {
		margin-top: 60px;
		max-width: 400px;
	}

	.widget {

		h3 {
			color: #16171A;
			font-size: 22px;
			font-weight: 700;
			line-height: 30px;
			text-transform: capitalize;
			margin: 0 0 1.3em;
			position: relative;
			text-transform: capitalize;
			padding-left: 10px;

			&:before {
				content: "";
				background-color: $theme-primary-color-s2;
				width: 2px;
				height: 20px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}


		}
	}

	>.widget+.widget {
		margin-top: 65px;
	}


	/*** search-widget ***/
	.search-widget {
		background: #F4F6F8;
		padding: 40px 30px;

		@media(max-width:1200px) {
			padding:35px 20px;
		}
		

		form div {
			position: relative;
		}

		input {
			background-color: $white;
			height: 55px;
			font-size: 16px;
			padding: 6px 50px 6px 20px;
			border: 0;
			box-shadow: none;
			@include transition-time(0.3s);
		}

		input:focus {
			background-color: transparentize($theme-primary-color, 0.9);
		}

		form button {
			background: transparent;
			font-size: 20px;
			color: $heading-color;
			background-color: transparent;
			border: 0;
			outline: 0;
			position: absolute;
			right: 10px;
			top: 52%;
			height: 50px;
			line-height: 50px;
			width: 50px;
			border-radius: 6px;
			@include translatingY();
		}
	}


	/*** about-widget ***/
	.about-widget {
		text-align: center;
		background: #F4F6F8;
		padding: 45px;
		position: relative;
		z-index: 1;

		@media(max-width:1200px) {
			padding:35px 20px;
		}


		.img-holder {
			margin: 0 auto;
			margin-bottom: 25px;
			width: 140px;
			height: 140px;

			img {
				border-radius: 50%;
				width: 100%;
			}

		}

		ul {
			list-style: none;
			display: flex;
			justify-content: center;

			li {
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}

				a {
					text-decoration: none;
					display: block;
					width: 42px;
					height: 37px;
					line-height: 37px;
					background-color: transparent;
					color: #7D7E80;
					border: 1px solid #E8E8E8;
					transition: all .3s ease-in-out;

					&:hover {
						background-color: $theme-primary-color-s2;
						border: 1px solid transparent;
						color: $white;
					}
				}
			}
		}

		h4 {
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 15px;
		}

		span {
			color: #727A7D;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 25px;
			display: block;
		}

		p {
			color: #5E5F63;
			font-size: 16px;
			font-weight: 400;
			line-height: 28.8px;
			margin-bottom: 30px;
		}

		a {
			font-family: $heading-font;
			font-size: 14px;
			color: $heading-color;
			text-decoration: underline;
		}

		a:hover {
			color: $theme-primary-color;
		}
	}


	/*** category-widget ***/
	.category-widget {
		background: #F4F6F8;
		padding: 40px 30px;

		@media(max-width:1200px) {
			padding:35px 20px;
		}

		ul {
			list-style: none;
		}

		ul li {
			font-size: 19px;
			position: relative;

			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}

			span {
				position: absolute;
				right: 15px;
			}
		}

		ul>li+li {
			margin-top: 20px;
		}

		ul a {
			display: block;
			font-size: 16px;
			color: #445658;
			font-size: 16px;
			font-weight: 400;
			line-height: 26px;
			padding: 12px 15px;
			background: $white;
			border-radius: 2px;
			border: 1px solid #F8F1EE;

			span {
				display: inline-block;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				border-radius: 50%;
				font-size: 18px;

				@include media-query(767px) {
					font-size: 14px;
					font-size: calc-rem-value(14);
				}
			}
		}

		ul a:hover,
		ul li:hover:before,
		ul li .active {
			color: $white;
			background: $theme-primary-color-s2;
		}
	}


	/*** recent-post-widget ***/
	.recent-post-widget {
		background: #F4F6F8;
		padding: 40px 30px;

		@media(max-width:1200px) {
			padding:35px 20px;
		}

		.post {
			overflow: hidden;
		}

		.posts>.post+.post {
			margin-top: 15px;
			padding-top: 15px;
		}

		.post .img-holder {
			width: 100px;
			float: left;

			img {
				width: 100%
			}
		}

		.post .details {
			width: calc(100% - 100px);
			float: left;
			padding-left: 20px;
		}

		.post h4 {
			margin: 0 0 0.3em;
			color:  #16171A;
			font-size: 16px;
			font-weight: 600;
			line-height: 28.8px;

			@include media-query(1199px) {
				margin: 0;
			}

			@include media-query(767px) {
				font-size: 16px;
			}
		}

		.post h4 a {
			display: inline-block;
			color: $heading-color;
		}

		.post h4 a:hover {
			color: $theme-primary-color;
		}

		.post .details .date {
			color: $text-color;
			color: #888686;
			font-size: 15px;
			font-weight: 400;
			line-height: 26px;
			text-transform: capitalize;

			i {
				display: inline-block;
				padding-right: 7px;
				color: $theme-primary-color-s2;
			}
		}
	}


	/*** instagram-widget ***/

	.wpo-instagram-widget {

		ul {
			display: flex;
			flex-wrap: wrap;
			list-style: none;

			li {
				-ms-flex: 0 0 33.33%;
				-webkit-box-flex: 0;
				flex: 0 0 33.33%;
				max-width: 33.33%;
				margin-bottom: 5px;
				padding: 0px 3px;

				img {
					width: 100%;
					border-radius: 6px;
				}
			}
		}

	}


	/*** tag-widget ***/
	.tag-widget {
		background: #F4F6F8;
		padding: 40px 30px;

		@media(max-width:1200px) {
			padding:35px 20px;
		}

		ul {
			overflow: hidden;
			list-style: none;
		}

		ul li {
			float: left;
			margin: 0 8px 8px 0;
		}

		ul li a {
			font-size: 15px;
			font-size: calc-rem-value(15);
			display: inline-block;
			padding: 5px 18px;
			color: #77787B;
			background: #FFF;
		}

		ul li a:hover {
			background: $theme-primary-color-s2;
			color: $white;
		}
	}

	/*** wpo-contact-widget ***/

	.wpo-contact-widget {
		border: 0;
		// background: url(../../images/bg-2.jpg) no-repeat center center;
		position: relative;
		background-size: cover;
		z-index: 1;
		padding: 30px 40px;

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(22, 57, 103, 0.9);
			content: "";
			z-index: -1;
		}

		@media(max-width:1200px) {
			padding: 20px;
		}

		h2 {
			font-size: 36px;
			font-weight: 700;
			text-align: left;
			color: $white;
			margin-bottom: 20px;

			&::before {
				left: 28px;
				// background: url(../../images/Single-title-shape2.png) no-repeat right center;
			}
		}

		p {
			color: $white;
			font-size: 18px;
		}


		a {
			display: inline-block;
			padding: 10px 20px;
			border: 1px solid $white;
			font-size: 18px;
			color: $white;
			padding-right: 90px;
			position: relative;
			margin-top: 10px;

			&::before {
				font-family: "themify";
				content: "\e628";
				font-size: 18px;
				position: absolute;
				right: 15px;
				top: 50%;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
	}
}


.wpo-blog-pg-section.blog-pg-left-sidebar {
	.blog-sidebar {
		@include widther(992px) {
			padding-left: 0px;
			padding-right: 45px;
		}
	}
}


.wpo-blog-single-section.wpo-blog-single-left-sidebar-section {
	.blog-sidebar {
		@include widther(992px) {
			padding-left: 0px;
			padding-right: 45px;
		}
	}
}