.wpo-section-title {
	margin-bottom: 60px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	h2 {
		color: $theme-primary-color-s2;
		font-family: $base-font;
		font-size: 16px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 1.76px;
		text-decoration-line: underline;
		text-transform: uppercase;
		position: relative;
		padding-left: 20px;
		display: inline-block;

		@include media-query(767px) {
			font-size: 14px;
		}

		&::before {
			position: absolute;
			left: 0;
			top: 45%;
			width: 14px;
			height: 16px;
			transform: translateY(-50%);
			content: url(../../images/title-shape.svg);
		}
	}


	h3 {
		text-transform: capitalize;
		color: $heading-color;
		font-family: $heading-font;
		font-size: 48px;
		font-weight: 700;
		line-height: 55px;
		margin-bottom: 0;

		@include media-query(991px) {
			font-size: 36px;
			line-height: 45px;
		}
		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}


		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p {
		font-size: 18px;
	}

}