/*17. track-page */
.track-page {

    h2 {
        margin-bottom: 60px;
    }

    .wrape {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:991px) {
            flex-wrap: wrap;
        }
    }

    .item {
        width: 260px;
        text-align: center;
        position: relative;
        margin-bottom: 30px;

        @media (max-width:1199px) {
            width: 230px;
        }

        @media (max-width:991px) {
            flex-basis: 50%;
        }

        @media (max-width:575px) {
            flex-basis: 100%;
        }

        .icon {
            max-width: 185px;
            max-height: 184px;
            line-height: 185px;
            margin: 0 auto;
            border-radius: 100px;
            background: #f1f1f1;
            display: block;
            margin-bottom: 10px;
            outline: 1px dotted #C1C1C1;
            outline-offset: -15px;
            transition: all .3s ease-in-out;
            position: relative;

            @media (max-width:1199px) {
                max-width: 130px;
                max-height: 130px;
                line-height: 130px;
            }

            span {
                color: #FFF;
                text-align: center;
                font-family: $heading-font;
                font-size: 18px;
                font-weight: 700;
                line-height: 35px;
                width: 36px;
                height: 36px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 14px;
                top: 13px;
                background: $theme-primary-color-s2;

                @media (max-width:1199px) {
                    right: 0px;
                    top: 0px;
                    font-size: 14px;
                }
            }

            i {
                font-size: 75px;
                color: #0B3742;

                @media (max-width:1199px) {
                    font-size: 50px;
                }
            }
        }

        h2 {
            color: #101212;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 0;

            @media (max-width:1199px) {
                font-size: 17px;
                line-height: 50px;
            }

            @media (max-width:991px) {
                line-height: 30px;
            }
        }

        .shape {
            position: absolute;
            top: 40%;
            right: -35%;
            transform: translateY(-50%);

            @media (max-width:1399px) {
                right: -22%;
            }

            @media (max-width:1199px) {
                right: -20%;
                top: 33%;
            }

            @media (max-width:991px) {
                right: -15%;
                top: 33%;
            }

            @media (max-width:575px) {
                display: none;
            }
        }

        &:last-child {
            .shape {
                display: none;
            }
        }

        @media (max-width:991px) {
            &:nth-child(2) {
                .shape {
                    display: none;
                }
            }
        }
    }

    .active {


        .icon {
            background: $theme-primary-color-s2;
            outline: 1px dotted #f1f1f1;

            span {
                color: $theme-primary-color-s2;
                background: $white;

                @media (max-width:1199px) {
                    right: 0px;
                    top: 0px;
                    font-size: 14px;
                }
            }

            i {
                color: $white;
            }
        }

    }

    .order-detail {
        border-radius: 9px;
        padding: 30px;
        background: #FFF;
        box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.05);
        margin-top: 50px;

        h3 {
            color: #101212;
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(237, 237, 237, );
            padding-bottom: 10px;
        }

        ul {
            li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                text-transform: capitalize;

                @media (max-width:575px) {
                    font-size: 15px;
                    line-height: 25px;
                    flex-direction: column;
                }

                span {
                    color: $heading-color;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 33px;

                    &:last-child {
                        color: #20273C;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}