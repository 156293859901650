/*--------------------------------------------------------------
2. header
--------------------------------------------------------------*/


/*-- 2.1 topbar --*/
.topbar {
	padding-left: 235px;
	padding-right: 195px;
	background: #EBEBEB;

	@media (max-width:1700px) {
		padding: 0 100px;
	}

	@media (max-width:1500px) {
		padding: 0 10px;
	}

	.contact-info {
		@media (max-width:991px) {
			text-align: center;
		}

		li {
			display: inline-flex;
			align-items: center;
			color: #000;
			font-family: "DM Sans";
			font-size: 15px;
			font-weight: 400;
			padding: 10px 0;

			@media (max-width:991px) {
				padding-bottom: 0;
			}

			i {
				color: #ED6637;
				padding-right: 7px;

			}

			&:last-child {
				i {
					font-size: 13px;
				}
			}

			span {
				color: #000;
			}

			&:last-child {
				padding-left: 35px;

				@media (max-width:575px) {
					padding-left: 0;
				}
			}
		}
	}


	.contact-into {
		display: flex;
		align-items: center;
		justify-content: end;

		@media (max-width:991px) {
			justify-content: center;
		}

		@media (max-width:575px) {
			flex-wrap: wrap;
		}
	}

	.pryment-selector {
		padding-left: 30px;

		@media (max-width:757px) {
			padding-left: 10px;
		}

		@media (max-width:575px) {
			padding-left: 0;
		}
	}

	.social-media {
		padding-left: 60px;

		@media (max-width:575px) {
			padding-left: 10px;
			padding-bottom: 10px;
		}

		li {
			display: inline-block;
			// color: $black;
			color: $white;
			font-size: 14px;
			font-weight: 500;

			@media (max-width:575px) {
				font-size: 14px;
			}

			a {
				padding: 0 8px;
				color: rgba(0, 0, 0, 0.72);
				transition: all .3s ease-in-out;

				&:hover {
					color: #ED6637;
				}
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}


	.language-selector {
		position: relative;
		display: inline;
		color: #000;
		font-size: 16px;
		font-weight: 500;

		@media (max-width:575px) {
			font-size: 14px;
		}
	}

	.language-selector select {
		display: none;
	}

	.language-selector .custom-select {
		position: relative;
		display: inline;
		cursor: pointer;
		padding: 10px;
		padding-right: 20px;

	}

	.custom-arrow {
		position: absolute;
		right: 0;
		top: 0;
		padding-left: 5px;
		color: #000;
		font-size: 16px;

		@media (max-width:575px) {
			font-size: 14px;
			padding-left: 2px;
		}
	}

	.language-selector .custom-select img {
		vertical-align: middle;
		margin-right: 5px;
	}

	.language-selector .custom-options {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		border: 1px solid #ccc;
		background-color: white;
		border-radius: 4px;
		z-index: 9999;
	}

	.language-selector .custom-options div {
		padding: 6px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;

		img {
			margin-right: 5px;
		}
	}

	.language-selector .custom-options div:hover {
		background-color: #f0f0f0;
	}

	.language-selector.active .custom-options {
		display: block;
	}

	.pryment-selector select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		padding: 10px 15px;
		padding-left: 10px;
		// color: #000;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		border: 1px solid transparent;
		background: transparent;
		background-image: none;
		cursor: pointer;
		position: relative;


		@media (max-width:575px) {
			font-size: 14px;
		}

		&:focus-visible {
			outline: 0px;
		}

		option {
			// color: #000;
			color: #fff;
			font-size: 16px;
			font-weight: 500;

			@media (max-width:575px) {
				font-size: 14px;
			}
		}
	}

	.pryment-selector {
		position: relative;
		display: inline-block;

	}

	.pryment-selector::after {
		font-family: flaticon_logistics !important;
		content: "\f121";
		color: #000;
		font-size: 16px;
		position: absolute;
		right: -5px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}


}

/* 2.2 navigation */
.wpo-site-header {
	/* navigation open and close btn hide for width screen */
	/* style for navigation less than 992px */
	/*navbar collaps less then 992px*/
	/*** cart-search-contact ***/
	background-color: #0F1525;
}

.wpo-site-header .navigation {
	background-color: #0F1525;
	margin-bottom: 0;
	padding: 0;
	border: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-o-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
}

.sticky-header {
	width: 100%;
	position: fixed;
	left: 0;
	top: -400px;
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-o-transition: all 1s;
	-ms-transition: all 1s;
	transition: all 1s;
	-webkit-box-shadow: 0 -5px 15px #086ad84a;
	box-shadow: 0 -5px 15px #086ad84a;
}

.sticky-on {
	opacity: 1;
	top: 0;
	visibility: visible;
}

.wpo-site-header .navigation>.container {
	position: relative;
}

@media (min-width: 991px) {
	.wpo-site-header {
		padding: 0 238px;
		position: relative;
		z-index: 11;
	}
}

.wpo-site-header {
	.row {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.wpo-site-header .navigation {
		padding: 20px 0;
	}
}

.wpo-site-header #navbar {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.wpo-site-header #navbar>ul li a:hover,
.wpo-site-header #navbar>ul li a:focus {
	text-decoration: none;
	color: $theme-primary-color;
}

@media screen and (min-width: 992px) {


	.wpo-site-header #navbar li {
		position: relative;
	}

	.wpo-site-header #navbar>ul>li>a:before {
		position: absolute;
		left: 20px;
		top: 45%;
		width: 5px;
		height: 5px;
		// background: $theme-primary-color-s2;
		background: #0F1525;
		content: "";
		opacity: 0;
		visibility: hidden;
		transition: all .3s;
		border-radius: 10px;
		transform: translateX(-50%);

		@media (max-width: 1499px) {
			left: 0;
		}
	}


	.wpo-site-header #navbar>ul>li>a:hover:before,
	.wpo-site-header #navbar>ul>li>a.active:before {
		opacity: 1;
		visibility: visible;
	}

	.wpo-site-header #navbar>ul>li>.sub-menu>.menu-item-has-children>a {
		position: relative;
	}

	.wpo-site-header #navbar>ul>li>.sub-menu>.menu-item-has-children>a:before {
		font-family: "themify";
		content: "\e649";
		font-size: 11px;
		font-size: 0.73333rem;
		position: absolute;
		right: 15px;
		top: 58%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.wpo-site-header #navbar>ul>li:hover>.sub-menu {
		top: 100%;
		visibility: visible;
		opacity: 1;
	}

	.wpo-site-header #navbar .sub-menu>li:hover>.sub-menu {
		left: 100%;
		visibility: visible;
		opacity: 1;
	}
}

.wpo-site-header .container-fluid {
	padding-right: 0;
}

@media (max-width: 991px) {
	.wpo-site-header {
		/* class for show hide navigation */
	}

	.wpo-site-header .container {
		width: 100%;
		max-width: 100%;
	}

	.wpo-site-header .header-right {
		right: -10px;
	}

	.wpo-site-header .navbar-header button span {
		background-color: #0F1525;
		width: 20px;
		display: block;
		height: 2px;
		margin-bottom: 5px;
	}

	.wpo-site-header .navbar-header button span:last-child {
		margin: 0;
	}
}

.wpo-site-header #navbar {
	ul {
		list-style: none;
	}
}

@media (max-width: 991px) and (max-width: 991px) {
	.wpo-site-header #navbar {
		top: 0;
		visibility: visible;
		opacity: 1;
	}
}

@media (max-width: 991px) {
	.wpo-site-header #navbar>ul {
		z-index: 101;
	}

	.wpo-site-header #navbar ul a {
		// color: #000;
		color: #fff;
	}

	.wpo-site-header #navbar ul a:hover,
	.wpo-site-header #navbar ul li.current a {
		color: $theme-primary-color;
	}

	.wpo-site-header #navbar .close-navbar .ti-close {
		position: relative;
		top: 1px;
	}

	.wpo-site-header .navbar-toggler .first-angle,
	.wpo-site-header .navbar-toggler .last-angle {
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}

	.wpo-site-header .x-close .middle-angle {
		opacity: 0;
	}

	.wpo-site-header .x-close .first-angle {
		position: absolute;
		-webkit-transform: rotate(-44deg);
		top: 16px;
		left: 10px;
	}

	.wpo-site-header .x-close .last-angle {
		-webkit-transform: rotate(44deg);
		position: absolute;
		top: 16px;
		right: 10px;
	}
}

@media (max-width: 767px) {
	.wpo-site-header .navbar-header .navbar-brand {
		font-size: 24px;
	}

	.wpo-site-header #navbar .navbar-nav {
		margin: 0;
	}
}

@media (max-width: 991px) {
	.wpo-site-header .navbar-collapse.collapse {
		display: none;
	}

	.wpo-site-header .navbar-collapse.collapse.in {
		display: block;
	}

	.wpo-site-header .navbar-header .collapse,
	.wpo-site-header .navbar-toggle {
		display: block;
	}

	.wpo-site-header .navbar-header {
		float: none;
		position: relative;
		z-index: 99;
		text-align: center;
	}

	.wpo-site-header .navbar-right {
		float: none;
	}

	.wpo-site-header .navbar-nav {
		float: none;
	}

	.wpo-site-header .navbar-nav>li {
		float: none;
	}
}

.wpo-site-header .header-right {
	display: flex;
	justify-content: start;
	z-index: 991;
	position: relative;
	align-items: center;
	margin-left: -80px;
	margin-top: 20px;

	@media (max-width:1499px) {
		margin-top: 0px;
		justify-content: end;
	}

	.call {
		display: flex;
		align-items: center;
		margin-right: 95px;

		@media (max-width:1699px) {
			margin-right: 10px;
		}

		@media (max-width:575px) {
			display: none;
		}

		.text {
			margin-left: 15px;

			@media (max-width:1499px) {
				display: none;
			}

			span {
				color: #828A8D;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
			}

			h4 {
				color: #1EAE98;
				font-size: 16px;
				font-weight: 600;
				text-transform: uppercase;
				margin-bottom: 0;
			}
		}
	}
}

.wpo-site-header .header-right button {
	padding: 0;
	border: 0;
	outline: 0;
}

.wpo-site-header .header-right>div {
	float: left;
	position: relative;

}

.wpo-site-header .header-right {
	.close-form {
		position: absolute;
		right: -280px;
		top: -25px;

		@media (max-width:1699px) {
			right: -150px;
		}

		@media (max-width:1499px) {
			position: unset;
		}

		@media (max-width:991px) {
			display: none;
		}

		.theme-btn {
			padding: 25px 140px 25px 40px;
			text-transform: capitalize;
			color: #FFF;
			font-family: $heading-font;
			font-size: 18px;
			font-weight: 700;
			line-height: 42px;
			background: $theme-primary-color;
			display: flex;
			align-items: center;

			@media (max-width:1880px) {
				padding: 30px 110px 30px 40px;
			}

			@media (max-width:1699px) {
				padding: 30px 40px;
			}

			@media (max-width:1499px) {
				padding: 10px 25px;
				font-size: 18px;
				line-height: 20px;
			}

			@media (max-width:1399px) {
				padding: 7px 20px;
				font-size: 16px;
				line-height: 20px;
			}

			i {
				margin-left: 20px;
				font-size: 25px;
				line-height: 45px;
				padding-top: 7px;

				@media (max-width:1399px) {
					margin-left: 5px;
					font-size: 20px;
					line-height: 35px;
				}
			}
		}
	}
}

@media (max-width: 450px) {
	.wpo-site-header .header-right>div {
		margin-right: 10px;
	}

	.wpo-site-header .header-right {
		right: -30px;
	}
}

.wpo-site-header .header-right .header-search-form-wrapper {
	position: relative;
}



.wpo-site-header .header-right .header-search-form-wrapper .fi:before {
	font-size: 22px;
}

.wpo-site-header .header-right .cart-toggle-btn {
	@media(max-width:450px) {
		display: none;
	}
}

.wpo-site-header .header-right .search-toggle-btn,
.wpo-site-header .header-right .cart-toggle-btn {
	line-height: 0.66em;
	color: $white;
	width: 50px;
	height: 50px;
	border: 0;
	background: transparent;


	i {
		font-size: 20px;
	}


	@media(max-width:1200px) {
		width: 50px;
		height: 50px;
	}

	@media(max-width:991px) {
		width: 40px;
		height: 40px;
	}

	.fi {
		&:before {
			@media(max-width:991px) {
				font-size: 16px;
			}
		}
	}

	.ti-close {
		display: block;
		line-height: 20px;
		position: relative;
		top: -2px
	}

}

/*** cart-search-contact ***/
.cart-search-contact {

	button {
		background: transparent;
		padding: 0;
		border: 0;
		outline: 0;
	}

	.fi {
		font-size: 30px;
		font-size: calc-rem-value(30);
		color: $dark-gray;
	}

	>div {
		float: left;
		position: relative;
	}

	>div+div {
		margin-left: 12px;
	}

	.header-search-form {
		position: absolute;
		width: 350px;
		right: 0;
		top: 75px;
		transform: scale(0);
		opacity: 0;
		visibility: hidden;
		background: $white;
		padding: 30px;
		border-radius: 6px;
		@include transition-time(0.5s);
		box-shadow: -2px 18px 40px -9px rgba(153, 153, 153, 1);

		@include media-query(991px) {
			top: 65px;
		}

		@include media-query(767px) {
			right: 15px;
		}

		@include media-query(575px) {
			width: 260px;
			padding: 15px;
		}
	}

	.header-search-form-wrapper .fi:before {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	form div {
		position: relative;

		button {
			position: absolute;
			right: 15px;
			top: 50%;
			@include translatingY();

			.fi {
				&:before {
					font-size: 18px !important;
				}
			}
		}
	}

	input {
		width: 100%;
		height: 50px;
		padding: 6px 20px;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		border: 1px solid $border-color;

		&:focus {
			box-shadow: none;
			border-color: $theme-primary-color;
		}
	}


}

.wpo-site-header .header-right .header-search-content-toggle {
	opacity: 1;
	visibility: visible;
	right: 0;
	transform: scale(1);
}

.mini-cart {
	margin-right: 20px;
}

.mini-cart .cart-count {
	background: $theme-primary-color;
	width: 19px;
	height: 19px;
	font-size: 10px;
	line-height: 17px;
	color: white;
	position: absolute;
	top: -2px;
	right: -5px;
	border-radius: 50%;
	font-weight: 600;

}

.mini-cart-content {
	background: $white;
	width: 320px;
	height: 100%;
	z-index: 100;
	position: fixed;
	right: -320px;
	top: 0;
	opacity: 0;
	visibility: hidden;
	@include transition-time(0.5s);
	box-shadow: -2px 18px 40px -9px rgba(153, 153, 153, 1);

	@media(max-width:575px) {
		width: 290px;
	}


	.mini-cart-close {
		display: block;
		width: 40px;
		height: 40px;
		background-color: rgb(51, 51, 51);
		color: rgb(255, 255, 255);
		font-size: 0px;
		text-align: center;
		overflow: hidden;
		position: relative;
		float: right;
		line-height: 54px;
		border-radius: 3px;
		right: 10px;
		top: 20px;

		i {
			font-size: 15px;
		}
	}

	p {
		font-size: 15px;
		font-size: calc-rem-value(15);
		font-weight: 500;
		color: $heading-color;
		margin: 0;
		text-transform: uppercase;
	}

	.mini-cart-items {
		padding: 100px 20px 25px;
	}

	.mini-cart-item {
		padding-top: 15px;
		margin-top: 15px;
		border-top: 1px solid #efefef;
	}

	.mini-cart-item:first-child {
		padding-top: 0;
		margin-top: 0;
		border-top: 0;
	}


	.mini-cart-item-image {
		width: 50px;
		height: 50px;
		border: 2px solid #f3f3f3;
		float: left;
		margin-right: 15px;
	}

	.mini-cart-item-image a,
	.mini-cart-item-image img {
		display: block;
		width: 46px;
		height: 46px;
	}

	.mini-cart-item-des {
		position: relative;
		overflow: hidden;
	}

	.mini-cart-item-des a {
		font-size: 14px;
		font-size: calc-rem-value(14);
		font-weight: 600;
		text-align: left;
		color: $heading-color;
	}

	.mini-cart-item-des a:hover {
		color: $theme-primary-color;
	}


	.mini-cart-item-des .mini-cart-item-price {
		font-size: 13px;
		font-size: calc-rem-value(13);
		color: #888;
		display: block;
		margin-top: 3px;
	}

	.mini-cart-item-des .mini-cart-item-quantity {
		font-size: 12px;
		font-size: calc-rem-value(12);
		color: #444;
		display: block;
		position: absolute;
		right: 0;
		top: 6px;
	}

	.visible-icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 300px;
		height: 300px;
		opacity: .02;
		z-index: -1;
	}

	.mini-cart-action {
		padding: 20px 0 30px;
		text-align: center;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);

		.theme-btn-s2 {
			float: right;
		}

		.mini-btn {
			width: 240px;
		}
	}

	.mini-cart-action .mini-checkout-price {
		font-size: 16px;
		font-size: calc-rem-value(16);
		font-weight: 600;
		color: $heading-color;
		display: block;
		margin-bottom: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			color: #c79652;
			font-size: 20px;
		}
	}
}

.mini-cart-content-toggle,
.mini-wislist-content-toggle {
	opacity: 1;
	visibility: visible;
	right: 0;
}

@media (max-width: 991px) {
	.page-wrapper {
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}

	.body-overlay:before {
		content: "";
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.9);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}
}

.header-search-area {
	background: #fff;
	width: 100%;
	padding: 100px 0;
	text-align: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
}

.header-search-area h3 {
	font-size: 45px;
	margin: 0 0 1em;
}

.header-search-area form {
	position: relative;
}

.header-search-area input {
	height: 50px;
	font-size: 18px;
	font-size: 1.2rem;
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #d0d0d0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.header-search-area input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: $theme-primary-color;
}

.header-search-area form button {
	background: transparent;
	border: 0;
	font-size: 20px;
	font-size: 1.33333rem;
	position: absolute;
	right: 0;
	bottom: 10px;
}

.header-search-area form button:focus {
	border: none;
	outline: none;
}

.header-search-area .close-form {
	position: absolute;
	right: 35px;
	top: 35px;
	bottom: auto;
	padding-bottom: 5px;
	border-bottom: 1px solid;
}

.header-search-area .close-form button {
	background: transparent;
	border: 0;
	outline: 0;
	font-size: 13px;
	font-size: 0.86667rem;
	text-transform: uppercase;
	color: #000;
	letter-spacing: 2px;
	z-index: 99;
}

.header-search-area .close-form button:focus {
	border: 0;
	outline: 0;
}


.wpo-site-header {

	.navigation {
		background-color: $white;
		margin-bottom: 0;
		padding: 0;
		border: 0;
		@include rounded-border(0);

		&::before {
			position: absolute;
			left: 42%;
			bottom: -25px;
			width: 107%;
			height: 110%;
			content: '';
			background: $white;
			transform: translateX(-50%);
			z-index: -1;
			clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);

			@media (max-width: 1499px) {
				display: none;
			}
		}

		>.container {
			position: relative;
		}

		@include media-query(1199px) {
			padding: 20px 0;
		}
	}

	#navbar {
		@include transition-time(0.5s);
		padding-top: 5px;
		margin-bottom: -10px;

		@media (max-width: 1499px) {
			padding-top: 0px;
			margin-bottom: 0px;
			justify-content: center;
		}

		>ul li a:hover,
		>ul li a.active,
		>ul li a:focus {
			color: $theme-primary-color-s2;
			text-decoration: underline;
		}

		// style for widther screen
		@include widther(992px) {
			li {
				position: relative;
			}

			>ul>li>a {
				font-size: 16px;
				padding: 30px 30px;
				display: block;
				// color: $black;
				color: $white;
				font-weight: 500;
				position: relative;
				text-transform: capitalize;
			}

			>ul .sub-menu {
				background-color: #fff;
				box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);
				width: 225px;
				position: absolute;
				padding: 20px 0;
				left: 0;
				top: 110%;
				z-index: 10;
				visibility: hidden;
				opacity: 0;
				@include transition-time(0.3s);
			}

			>ul>li .sub-menu a {
				font-size: 16px;
				display: block;
				padding: 10px 15px;
				color: $black;
				position: relative;
				overflow: hidden;
				font-weight: 500;
				text-transform: capitalize;


				&:hover,
				&.active {
					color: $theme-primary-color-s2;
					text-decoration: underline;
				}
			}

			>ul>li>.sub-menu .sub-menu {
				left: 110%;
				top: 0;
			}

			>ul>li>.sub-menu>.menu-item-has-children>a {
				position: relative;

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					position: absolute;
					right: 15px;
					top: 50%;
					@include translatingY();
				}
			}

			/*** hover effect ***/
			>ul>li:hover>.sub-menu {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}

			.sub-menu>li:hover>.sub-menu {
				left: 100%;
				visibility: visible;
				opacity: 1;
			}
		}

		@include media-query(991px) {

			>ul>li a {
				display: block;
				font-size: 15px;
				color: $white;
				font-weight: 600;
				text-transform: capitalize;

				&:hover,
				&.active {
					color: $white;
				}
			}

			>ul .sub-menu>li:last-child {
				border-bottom: 0;
			}

			>ul>li>.sub-menu a {
				padding: 8px 15px 8px 45px;
			}

			>ul>li>.sub-menu .sub-menu a {
				padding: 8px 15px 8px 65px;
			}

			>ul .menu-item-has-children>a {
				position: relative;
				color: #ddd;

				&:hover,
				&.active {
					color: #fff;
				}

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					font-size: calc-rem-value(11);
					position: absolute;
					right: 30px;
					top: 50%;
					@include translatingY();
					@include transition-time(0.3s);
				}

				&.rotate {
					&:before {
						transform: rotate(90deg);
						top: 29%;
					}
				}

				&.rotate {
					color: #fff;
				}
			}
		}
	}

	/* navigation open and close btn hide for width screen */
	@include widther(992px) {
		.navbar-header .open-btn {
			display: none;
		}

		#navbar .close-navbar {
			display: none;
		}
	}

	.menu-close {
		display: none;
	}


	/* style for navigation less than 992px */
	@include media-query (991px) {
		.container {
			width: 100%;
		}

		.mobail-menu button {
			background-color: $theme-primary-color;
			width: 40px;
			height: 35px;
			border: 0;
			padding: 5px 10px;
			outline: 0;
			position: relative;
			z-index: 20;

			&:focus {
				outline: none;
				box-shadow: none;
			}


			span {
				background-color: $white;
				width: 20px;
				display: block;
				height: 2px;
				margin-bottom: 5px;

				&:last-child {
					margin: 0;
				}
			}
		}

		#navbar {
			background: #006e5e;
			display: block !important;
			width: 300px;
			height: 100%;
			margin: 0;
			padding: 0;
			border: 0;
			position: fixed;
			left: -320px;
			top: 0px;
			z-index: 999999;
			opacity: 0;
			visibility: hidden;

			.navbar-nav {
				display: block;
			}

			>ul {
				position: relative;
				z-index: 101;
			}

			ul a {
				color: $black;
			}

			ul a:hover,
			ul li.current a {
				color: $theme-primary-color;
			}

			.navbar-nav {
				height: 100%;
				overflow: auto;
				padding-bottom: 60px;
			}

			.close-navbar {
				background-color: $white;
				width: 40px;
				height: 40px;
				color: $black;
				border: 0;
				outline: none;
				position: absolute;
				left: -41px;
				top: 90px;
				z-index: 20;

				.ti-close {
					position: relative;
					top: 1px;
				}
			}


		}

		.menu-close {
			display: block;
			z-index: 99;
			background: none;
			text-align: center;
			color: $white;
			border: 0;
			text-align: center;
			margin-left: auto;
			margin-top: 30px;
			margin-right: 30px;
			margin-bottom: 30px;
			width: 40px;
			height: 40px;
			line-height: 40px;
			border: 1px solid $white;
		}

		/* class for show hide navigation */
		.slideInn {
			left: 0 !important;
			opacity: 1 !important;
			visibility: visible !important;
		}

		.navbar-toggler .first-angle,
		.navbar-toggler .last-angle {
			@include transition-time(0.3s);
		}

		.x-close .middle-angle {
			opacity: 0;
		}

		.x-close .first-angle {
			position: absolute;
			-webkit-transform: rotate(-44deg);
			top: 16px;
			left: 10px;
		}

		.x-close .last-angle {
			-webkit-transform: rotate(44deg);
			position: absolute;
			top: 16px;
			right: 10px;
		}
	}

	@include media-query(767px) {
		.navbar-header .navbar-brand {
			font-size: 24px;
		}

		#navbar .navbar-nav {
			margin: 0;
		}
	}

	.navbar-header .navbar-brand {
		img {
			max-width: 170px;
			margin-top: 20px;

			@media (max-width:1499px) {
				margin-top: 0px;
			}
		}
	}


	/*navbar collaps less then 992px*/
	@include media-query (991px) {
		.navbar-collapse.collapse {
			display: none;
		}

		.navbar-collapse.collapse.in {
			display: block;
		}

		.navbar-header .collapse,
		.navbar-toggle {
			display: block;
		}

		.navbar-header {
			float: none;
		}

		.navbar-right {
			float: none;
		}

		.navbar-nav {
			float: none;
		}

		.navbar-nav>li {
			float: none;
		}
	}


}




@media(max-width: 440px) {
	.wpo-site-header .navbar-header .navbar-brand {
		img {
			max-width: 170px;
		}
	}
}

@media(max-width: 370px) {
	.wpo-site-header .navbar-header .navbar-brand {
		img {
			max-width: 140px;
		}
	}
}


@media(max-width: 1700px) {
	.wpo-site-header {
		padding: 0 100px;
	}
}

@media(max-width: 1500px) {
	.wpo-site-header #navbar>ul>li>a {
		padding: 30px 15px;
	}

	.wpo-site-header {
		padding: 0 10px;
	}

}

@media(max-width: 1400px) {
	.wpo-site-header #navbar>ul>li>a {
		padding: 30px 10px;
		font-size: 18px;
	}

}

@media(max-width: 1200px) {
	.wpo-site-header #navbar>ul>li>a {
		padding: 15px 15px;
		font-size: 16px;
	}


}

@media(max-width: 991px) {
	.wpo-site-header #navbar>ul>li>a {
		font-size: 15px;
	}
}

@media (max-width: 767px) {
	.header-right .theme-btn {
		padding: 20px 12px;
	}

	.wpo-site-header .header-right .close-form {
		display: none;
	}

}



.wpo-site-header .navigation.sticky-header {
	padding: 0 130px;

	@media (max-width:1499px) {
		padding: 0 100px;
	}

	@media (max-width:1399px) {
		padding: 0 80px;
	}

	@media (max-width:1199px) {
		padding: 20px;
	}

	@media (max-width:767px) {
		padding: 20px 10px;
	}

	&:before {
		display: none;
	}

	.navbar-header .navbar-brand img {
		margin-top: 0px;
	}

	#navbar {
		padding-top: 5px;
		margin-bottom: 0px;
	}

	.header-right {
		margin-top: 0px;
		justify-content: end;
	}


	.header-right .close-form .theme-btn {
		display: none;
	}
}




/* wpo-site-header-s2 */
.wpo-site-header-s2 {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 11;

	.topbar {
		padding: 0 250px;
		background: transparent;

		@media (max-width:1700px) {
			padding: 0 100px;
		}

		@media (max-width:1500px) {
			padding: 0 10px;
		}

		@media (max-width:991px) {
			padding: 0;
		}
	}

	.topbar .contact-info li span {
		color: $white;
	}

	.topbar .language-selector {
		color: $white;
	}

	.social-help {
		background: $theme-primary-color-s2;
		padding: 0 20px;

		@include media-query(991px) {
			display: none;
		}

		li {
			display: inline-block;

			a {
				color: #FFF;
				font-size: 15px;
				font-weight: 500;
				transition: all .3s ease-in-out;
				padding: 10px 15px;
				display: block;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.topbar .social-media {
		padding-left: 40px;

		@include media-query(991px) {
			padding-left: 0;
		}
	}

	.topbar .social-media li a {
		color: $white;

		&:hover {
			color: $theme-primary-color-s2;
		}
	}

	.wpo-site-header .navigation::before {
		display: none;
	}

	.wpo-site-header #navbar {
		padding-top: 0px;
		margin-bottom: 0px;
	}

	.wpo-site-header .navbar-header .navbar-brand img {
		max-width: 170px;
		margin-top: 0px;
	}


	.wpo-site-header .header-right {
		justify-content: end;
		margin-left: auto;
		right: -10px;
		margin-top: 0px;

		@media(max-width:575px) {
			right: -30px;
		}
	}

	.wpo-site-header .header-right .close-form {
		position: unset;
		padding-left: 20px;

	}

	.wpo-site-header .header-right .close-form .theme-btn {
		padding: 0px;
		text-transform: capitalize;
		background: #0BA08A;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 143px;
		color: #FFF;
		font-family: $base-font;
		font-size: 15px;
		font-weight: 500;
		border-radius: 6px;
		height: 42px;
	}

	.wpo-site-header {
		padding: 0 220px;
		position: relative;
		z-index: 11;

		@media (max-width:1700px) {
			padding: 0 100px;
		}

		@media (max-width:1500px) {
			padding: 0 10px;
		}

		@media(max-width:575px) {
			padding: 0;
		}
	}

	.wpo-site-header {
		background: transparent;
	}

	.wpo-site-header .navigation {
		border-radius: 7px;
		background: rgba(255, 255, 255, 0.09);
		// backdrop-filter: blur(10.5px);
		padding: 0 25px;

		@media (max-width:1199px) {
			padding: 0 10px;
		}

		@media (max-width:991px) {
			padding: 10px;
			margin-top: 20px;
		}

		@media(max-width:575px) {
			padding: 10px 0;
		}
	}

	.wpo-site-header #navbar>ul>li>a {
		padding: 38px 30px;
		color: $white;

		@media (max-width:1399px) {
			padding: 38px 20px;
		}

		@media (max-width:1199px) {
			padding: 25px 15px;
		}

		@media (max-width:991px) {
			padding: 15px 20px;
		}
	}



	@media screen and (min-width: 992px) {
		.wpo-site-header #navbar>ul>li>a:before {
			display: none;
		}
	}

	.wpo-site-header #navbar>ul li a:hover,
	.wpo-site-header #navbar>ul li a.active,
	.wpo-site-header #navbar>ul li a:focus {
		color: #FF7236; 
		text-decoration: underline;
	}

	.cart-search-contact .fi {
		color: $white;
	}

	.wpo-site-header .header-right .header-search-form-wrapper .fi:before {
		font-size: 20px;
	}

	.wpo-site-header .header-right .header-search-form-wrapper .fi:before {
		font-size: 20px;
	}

	.cart-search-contact form div button .fi:before {
		font-size: 18px !important;
		color: #000;
	}


	.wpo-site-header .navigation.sticky-header {
		border-radius: 0px;
		background: rgb(11 55 66);
		margin-top: 0;
	}

	.wpo-site-header .navigation.sticky-header #navbar {
		padding-top: 0px;
		margin-bottom: 0px;
	}

}

/* wpo-site-header-s3 */
.wpo-site-header-s3 {

	.navigation::before {
		display: none;
	}


	.header-right .close-form .theme-btn {
		padding: 27px 85px;
		text-align: center;

		@media (max-width:1499px) {
			padding: 30px 50px;
		}

		@media (max-width:1399px) {
			padding: 15px 20px;
		}
	}

	.navigation.sticky-header #navbar {
		padding-top: 0px;
	}

	.header-right .close-form {
		top: -29px;
	}

	#navbar>ul>li>a {
		padding: 42px 30px;

		@media (max-width:1399px) {
			padding: 35px 20px;
		}

		@media (max-width:1199px) {
			padding: 20px 10px;
		}

		@media (max-width:991px) {
			padding: 10px 25px;
		}
	}

	.header-right {
		margin-top: 0;
	}

	.navbar-header .navbar-brand img {
		margin-top: 0px;
	}

	#navbar {
		padding-top: 0px;
		margin-bottom: 0px;
	}


	.header-right .call {
		margin-right: 80px;

		@media (max-width:1699px) {
			margin-right: 10px;
		}
	}


	@media (max-width: 1499px) {
		 .navigation.sticky-header {
			padding: 0 70px;
		}
	}
	@media (max-width: 991px) {
		 .navigation.sticky-header {
			padding: 20px 10px;
		}
	}

}