.accordion-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.accordion-header {
    width: 100%;
    text-align: left;
    padding: 10px;
    background-color: #f7f7f7;
    cursor: pointer;
}

.accordion-content {
    padding: 10px;
    display: none;
}

.accordion-item.active .accordion-content {
    display: block;
}
