.service-sidebar {
  margin-right: 10px;

  @media (max-width:1199px) {
    margin: 0;
  }

  @media (max-width: 991px) {
    margin-top: 50px;
    max-width: 450px;
  }
}

.service-catagory ul li {
  margin-bottom: 13px;
}

.service-catagory ul li a {
  background: #F8F8F8;
  color: #16171A;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  padding-right: 10px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .3s ease-in-out;

  i {
    width: 54px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    color: #8893A8;
    transition: all .3s ease-in-out;
  }

  &:hover,
  &.active {
    background: $theme-primary-color-s2;
    color: $white;

    i {
      color: $white;
      background: rgb(255 255 255 / 15%);
    }
  }
}

.service-info {
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.92);
  text-align: center;
  width: 100%;
  margin-top: 40px;


  .icon {
    width: 88px;
    height: 76px;
    line-height: 76px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.09);
    color: $white;
    font-size: 35px;
    display: inline-block;
    margin-bottom: 30px;
  }


  h2 {
    color: #FFF;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    max-width: 244px;
    margin: 0 auto;
    margin-bottom: 40px;

  }

  span {
    color: #FFF;
    font-family: $heading-font;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1.95px;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
  }



  .num {
    span {
      border-radius: 24px;
      background: $theme-primary-color-s2;
      color: #FFF;
      text-align: center;
      font-family: $heading-font;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      display: block;
      padding: 8px 10px;
    }
  }

}