	/*15. wpo-contact-page */
	.office-info {
		padding-bottom: 120px;
		margin: 0 -10px;

		@media(max-width:1199px) {
			margin: 0px;
			padding-bottom: 80px;
		}

		@media(max-width:767px) {
			padding-bottom: 60px;
		}

		.col {


			.office-info-item {
				@media(max-width:1200px) {
					margin-bottom: 30px;
				}
			}

			&:last-child {
				.office-info-item {
					@media(max-width:1200px) {
						margin-bottom: 0px;
					}
				}
			}

		}

		.office-info-item {
			text-align: center;
			padding: 40px;
			margin: 0 10px;
			border-radius: 8px;
			border: 1px solid #FFF;
			box-shadow: 0px 15px 10px 0px rgba(194, 185, 185, 0.18);
			overflow: hidden;
			background: transparent;
			position: relative;
			transition: all .3s ease-in-out;

			@media(max-width:1199px) {
				margin: 0px;
				padding: 30px;
			}

			&::before {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				content: '';
				// background: #FF7236;
				background: #E02C95;
				z-index: -1;
				opacity: 0;
				visibility: hidden;
				transition: all .3s ease-in-out;
			}

			.office-info-icon {
				width: 85px;
				height: 85px;
				line-height: 85px;
				text-align: center;
				margin: 0 auto;

				.icon {
					.fi {
						&:before {
							font-size: 50px;
							color: $theme-primary-color-s2;
							transition: all .3s ease-in-out;
						}
					}
				}
			}

			.office-info-text {
				h2 {
					margin: 20px 0;
					color: #212121;
					font-size: 24px;
					font-weight: 700;
					text-transform: capitalize;
					transition: all .3s ease-in-out;
				}

				p {
					margin-bottom: 0;
					// color: #636776;
					font-size: 18px;
					font-weight: 500;
					line-height: 28px;
					transition: all .3s ease-in-out;
				}
			}


			&.active,
			&:hover {
				// background: url(../../images/office-info-bg.png) no-repeat;

				&::before {
					opacity: 0.95;
					visibility: visible;
				}

				.office-info-icon {

					.icon {
						.fi {
							&:before {
								color: $white;
							}
						}
					}
				}

				.office-info-text {
					h2 {
						color: $white;
					}

					p {
						color: $white;
					}
				}
			}
		}

	}

	.contact-left {
		max-width: 518px;
		margin: 0 auto;
		text-align: left;

		@media (max-width:1199px) {
			margin-left: 0;
		}

		@media (max-width:991px) {
			margin-bottom: 30px;
		}

		h2 {
			color: #0A1222;
			font-size: 44px;
			font-weight: 700;
			text-transform: capitalize;

			@media (max-width:767px) {
				font-size: 35px;
			}
		}

		p {
			color: #636776;
			font-size: 18px;
			font-weight: 400;
			line-height: 32px;
			margin-bottom: 30px;
		}

		img {
			width: 100%;
		}
	}


	.contact-right {
		max-width: 484px;
		padding-left: 40px;

		@media (max-width:1199px) {
			padding-left: 0;
		}

		.title {
			h2 {
				color: #212121;
				font-size: 44px;
				font-weight: 700;
				text-transform: capitalize;

				@media (max-width:767px) {
					font-size: 35px;
				}
			}

			p {
				color: #636776;
				font-size: 16px;
				font-weight: 400;
				line-height: 33px;
				margin-bottom: 30px;
			}
		}
	}


	.contact-form {

		.input-item {
			position: relative;
			margin-bottom: 40px;

			.fild {
				padding-bottom: 20px;
				padding-left: 30px;
				color: #636776;
				font-size: 18px;
				font-weight: 500;
				border: 0;
				width: 100%;
				height: 45px;
				border-bottom: 1px solid rgb(92, 92, 91, 0.3);

				&:focus-visible {
					outline: 0;
				}
			}

			label {
				position: absolute;
				left: 0;
				top: -2px;

				i {
					color: #212121;
					font-size: 20px;
				}
			}

			.srv-validation-message {
				display: none;
			}

			.textarea {
				height: 120px;
			}
		}

		.submitbtn {
			margin-bottom: 0;
			width: 222px;
			height: 63px;
			position: relative;

			.fild {
				padding: 0;
				background: $theme-primary-color-s2;
				height: 63px;
				line-height: 67px;
				color: $white;
				padding-left: 20px;
			}

			label {
				position: absolute;
				left: 30px;
				top: 54%;
				transform: translateY(-50%);

				i {
					color: $white;
					font-size: 23px;
				}
			}
		}

		::-webkit-input-placeholder {
			color: #636776;
		}

		::-moz-placeholder {
			color: #636776;
		}

		:-ms-input-placeholder {
			color: #636776;
		}

		:-moz-placeholder {
			color: #636776;
		}
	}