/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');


// fonts
$base-font-size: 15;
$base-font: "DM Sans";
$heading-font:  "Plus Jakarta Sans";



// color
$dark-gray: #042127;//
$body-color: #687693;
$white: #fff;
$light: #C2C2C2;//
$black: #000;
$black-s2: #0D1224;
// $small-black: #0B3742;//
$small-black: #4ba0b5;//
$cyan: #F6F2ED;//

// $theme-primary-color: #0BA08A;//
$theme-primary-color: #6d3a7c;//
$theme-primary-color-s2: #e02c95;//
// $theme-primary-color-s2: #FF7236;//
$body-bg-color: #fff;
$section-bg-color: #fafbfe;
$text-color: #6e6e6e;
$text-light-color: #535353;//
$text-light-color-s2: #20273C;//
// $text-light-color-s3: #0BA089;//
$text-light-color-s3: #0BA089;//
// $text-light-color-s4: #FF7D44;//
$text-light-color-s4: #a374b2;//
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;
