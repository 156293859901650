/*!
*    ______ _       _   _
*    |  ____| |     | | (_)
*    | |__  | | __ _| |_ _  ___ ___  _ __
*    |  __| | |/ _` | __| |/ __/ _ \| '_ \
*    | |    | | (_| | |_| | (_| (_) | | | |
*    |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*         https://www.flaticon.com
*/

@font-face {
    font-family: "flaticon_logistics";
    src: url("../fonts/flaticon_logistics.woff2?646180958cb6f5edc8017ae50c5cfdf7") format("woff2"),
        url("../fonts/flaticon_logistics.woff?646180958cb6f5edc8017ae50c5cfdf7") format("woff"),
        url("../fonts/flaticon_logistics.eot?646180958cb6f5edc8017ae50c5cfdf7#iefix") format("embedded-opentype"),
        url("../fonts/flaticon_logistics.ttf?646180958cb6f5edc8017ae50c5cfdf7") format("truetype"),
        url("../fonts/flaticon_logistics.svg?646180958cb6f5edc8017ae50c5cfdf7#flaticon_logistics") format("svg")
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon_logistics !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-call:before {
    content: "\f101";
}

.flaticon-road-with-broken-line:before {
    content: "\f102";
}

.flaticon-fast-delivery:before {
    content: "\f103";
}

.flaticon-boat-with-containers:before {
    content: "\f104";
}

.flaticon-logistics:before {
    content: "\f105";
}

.flaticon-fast-delivery-1:before {
    content: "\f106";
}

.flaticon-locations:before {
    content: "\f107";
}

.flaticon-logistics-1:before {
    content: "\f108";
}

.flaticon-customer-support:before {
    content: "\f109";
}

.flaticon-play-button:before {
    content: "\f10a";
}

.flaticon-play-button-1:before {
    content: "\f10b";
}

.flaticon-order:before {
    content: "\f10c";
}

.flaticon-credit-card:before {
    content: "\f10d";
}

.flaticon-boxes:before {
    content: "\f10e";
}

.flaticon-product-return:before {
    content: "\f10f";
}

.flaticon-product-return-1:before {
    content: "\f110";
}

.flaticon-truck:before {
    content: "\f111";
}

.flaticon-database:before {
    content: "\f112";
}

.flaticon-distribution-center:before {
    content: "\f113";
}

.flaticon-customer-support-1:before {
    content: "\f114";
}

.flaticon-truck-1:before {
    content: "\f115";
}

.flaticon-delivery-box:before {
    content: "\f116";
}

.flaticon-employee:before {
    content: "\f117";
}

.flaticon-worker:before {
    content: "\f118";
}

.flaticon-warehouse:before {
    content: "\f119";
}

.flaticon-air-plane:before {
    content: "\f11a";
}

.flaticon-right:before {
    content: "\f11b";
}

.flaticon-delivery:before {
    content: "\f11c";
}

.flaticon-tracking:before {
    content: "\f11d";
}

.flaticon-warehouse-1:before {
    content: "\f11e";
}

.flaticon-warehouse-2:before {
    content: "\f11f";
}

.flaticon-email:before {
    content: "\f120";
}

.flaticon-down:before {
    content: "\f121";
}

.flaticon-search:before {
    content: "\f122";
}

.flaticon-next:before {
    content: "\f123";
}

.flaticon-right-arrow:before {
    content: "\f124";
}

.flaticon-left-arrow:before {
    content: "\f125";
}

.flaticon-check:before {
    content: "\f126";
}

.flaticon-nanotechnology:before {
    content: "\f127";
}

.flaticon-shopping-cart:before {
    content: "\f128";
}

.flaticon-globe:before {
    content: "\f129";
}

.flaticon-mark:before {
    content: "\f12a";
}

.flaticon-star:before {
    content: "\f12b";
}

.flaticon-circuit:before {
    content: "\f12c";
}

.flaticon-profile:before {
    content: "\f12d";
}

.flaticon-comments:before {
    content: "\f12e";
}

.flaticon-right-arrow-1:before {
    content: "\f12f";
}

.flaticon-phone-call:before {
    content: "\f130";
}

.flaticon-play-button-arrowhead:before {
    content: "\f131";
}

.flaticon-clock:before {
    content: "\f132";
}

.flaticon-calendar:before {
    content: "\f133";
}

.flaticon-quote:before {
    content: "\f134";
}

.flaticon-back:before {
    content: "\f135";
}

.flaticon-location:before {
    content: "\f136";
}

.flaticon-location-1:before {
    content: "\f137";
}

.flaticon-document:before {
    content: "\f138";
}

.flaticon-edit:before {
    content: "\f139";
}

.flaticon-user:before {
    content: "\f13a";
}

.flaticon-send:before {
    content: "\f13b";
}

.flaticon-arrowhead-up:before {
    content: "\f13c";
}

.flaticon-facebook-app-symbol:before {
    content: "\f13d";
}

.flaticon-vimeo:before {
    content: "\f13e";
}

.flaticon-linkedin-big-logo:before {
    content: "\f13f";
}

.flaticon-twitter:before {
    content: "\f140";
}